.research-watchlists>div>div>div>div {
  padding-top: 0;
  padding-bottom: 0;
}

.research-watchlists>div>div>div>div>div>div {
  padding-top: 4px;
  padding-bottom: 4px;
}

.yellow-cell {
  background-color: #fffdec;
}

.prospects-actions {
  .date-picker {
    width: auto;
    .form-control-lg {
      &.font-size-custom {
        font-size: 100%;
      }
    }
  }
}

.leads-recycle-dashboard {
  .radio-btn:last-child {
    padding: 0;
    margin-bottom: 1rem;
  }
  .date-picker {
    .form-control-sm {
      height: calc(1.5em + 0.95rem + 2px);
    }
  }
}

.modal-header-bold {
  h1, h2, h3, h4, h5 {
    font-weight: bold;
  }
}

.research-modal-tiktok-icon svg path {
  fill: #70bbfd !important;
  transition: all 0.3s !important;

  &:hover {
    fill: #3ea3fc !important;
    transition: all 0.3s !important;
  }
}
.research-modal-tiktok-icon svg text {
  fill: #70bbfd !important;
  transition: all 0.3s !important;

  &:hover {
    fill: #3ea3fc !important;
    transition: all 0.3s !important;
  }
}
