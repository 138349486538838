.account {
  width: 100%;
  min-height: calc(100vh - 81px);
  display: flex;
  overflow-y: auto;

  &.account--not-photo {
    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }
  }

  &.account--photo {
    background: url('../../shared/img/404/bg_404.png') no-repeat center!important;
    background-size: cover;

    .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: #ffffff;
    }

    .form__form-group-icon, .form__form-group-button {
      background: transparent;
    }

    .form__form-group-icon, input:not(:last-child) {

      @include directify($directions) {
        #{directed('border-right')}: none
      }
    }

    .form__form-group-button, input {
      @include directify($directions) {
        #{directed('border-left')}: none
      }
    }

    .form__form-group-button.active {
      border-color: #eff1f5;

      svg {
        fill: $color-accent;
      }
    }

    input {
      color: #ffffff;
      box-shadow: none;

      &:focus {
        border-color: #eff1f5;
      }
    }

    p {
      color: #ffffff;
    }

    .account__title {
      color: #ffffff;
    }
  }
}

.account__wrapper {
  @include directify($directions) {
    text-align: directed('left');
  }
  margin: auto;
  padding: 10px 8px;
}

.account__card {

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  background-color: #ffffff;
  padding: 50px 60px;
  max-width: 520px;
  width: 100%;
}

.account__profile {
  text-align: center;
}

.register__btns {
  @include directify($directions) {
    #{directed('margin-left')}: 0px!important;
  }
}

.account__btns {
  display: flex;
  width: calc(100% + 10px);
  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: -10px;
  }
  margin-top: -10px;
  margin-bottom: -20;

  a {
    @include directify($directions) {
      #{directed('margin-right')}: 0px;
      #{directed('margin-left')}: 10px;
    }
    margin-bottom: 20px;
    white-space: nowrap;
  }
}

.account__btn {
  width: 100%;
  @include directify($directions) {
    #{directed('margin-right')}: 0px;
  }
}

.account__avatar {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.account__name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 15px;
  margin-top: 5px;
}

.account__sub {
  margin-top: 0;
  margin-bottom: 10px;
  color: $color-additional;
  font-size: 11px;
  line-height: 15px;
}

.account__forgot-password {
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  bottom: -18px;
  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  a {
    color: $color-blue;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

.account__or {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
  position: relative;

  &:before, &:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #dddddd;
    position: absolute;
    top: 10px;
  }

  &:before {
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
  }

  &:after {
    @include directify($directions) {
      #{directed('right')}: 0px;
    }
  }
}

.account__social {
  text-align: center;
}

.account__social-btn {
  display: inline-block;
  height: 38px;
  width: 38px;
  border-radius: 5px;
  padding: 9px;
  @include directify($directions) {
    #{directed('margin-right')}: 10px;
  }
  transition: all 0.3s;
  border: none;

  &:last-child {
    @include directify($directions) {
      #{directed('margin-right')}: 0px;
    }
  }

  svg {
    fill: #ffffff;
    height: 20px;
    width: 20px;
  }

  &.account__social-btn--facebook {
    background: #4766a4;

    &:hover {
      background: darken(#4766a4, 10%);
    }
  }

  &.account__social-btn--google {
    background: #c74d4d;

    &:hover {
      background: darken(#c74d4d, 10%);
    }
  }
  &.account__social-btn--firebase {
    background: #4287f5;
    margin-top: 20px;
    padding: 0;
    padding-left: 5px;

    &:before {
      background-color: darken(#4287f5, 10%);
    }

    &:hover {

      background: darken(#4287f5, 10%);
    }
  }

  &.account__social-btn--auth0 {
    margin-top: 20px;
  }
}

.account__head {
  margin-bottom: 30px;
  @include directify($directions) {
    #{directed('padding-left')}: 10px;
    #{directed('border-left')}: 4px solid $color-blue;
  }
}

.account__logo {
  font-weight: 700;
}

.account__logo-accent {
  color: $color-blue;
}

.account__have-account {
  text-align: center;
  margin-top: 20px;

  a {
    color: $color-blue;
    transition: all 0.3s;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

.login-bg {
  background: #edf2fa url('../../../public/images/login/il-bg.jpg') no-repeat center center/100%!important;
}
.login-admin-bg {
  background: #f1f1f1 url('../../../public/images/login/il-admin-bg.jpg') no-repeat center center/100%!important;
}
.login-brands-bg {
  background: #f1f1f1 url('../../../public/images/login/il-brands-bg.jpg') no-repeat center center/100%!important;
}
.login-bg .account__card, .login-admin-bg .account__card, .login-brands-bg .account__card {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 30px 30px;
  box-shadow: 0 0 20px rgba(0,0,0,0.5);
}
.login-admin-bg .account__card {
  background-color: #f2f2fa;
  border: 4px solid #1d49a1;
}
.login-brands-bg .account__card {
  background-color: #e5f3ff;
}
.login-brands-bg .account__card h2 {
  color: #70bbfd!important;
}
.account__head-login img {
  max-height: 30px;
  width: auto;
  margin-bottom: 10px;
}
.account__head-login {
  text-align: center;
}
.account__card h2  {
  color: #000!important;
  font-size: 35px;
  font-weight: bold;
}
.login-admin-bg .account__card h2 {
  color: #1d49a1!important;
}
.account__card h4 {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.account__card .btn {
  background-color: #1d49a1;
  border-color: #1d49a1;
}
.captcha-div div {
  margin-left: auto!important;
  margin-right: auto!important;
}
.login-bg .alert {
  max-width: 302px;
  margin-top: 10px;
  &.big-width {
    max-width: 360px;
  }
}
.login-footer {
  bottom: 0px;
  background-color: #fff;
  width: 100%;
  padding: 30px 0;
  overflow: hidden;
}
.login-footer .row {
  width: 60%;
  margin: 0 auto;
}
.login-footer a {
  color: #000;
  text-decoration: underline;
}
@media screen and (max-width: 700px) {
  .account {
    min-height: 100vh;
  }
  .login-bg, .login-admin-bg {
    background-size: 200%!important;
  }
  .login-footer {
    margin-top: 10px;
  }
}
@media screen and (max-width: 520px) {

  .account__card {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 425px) {

  .account__btns {
    flex-wrap: wrap;
  }
}
