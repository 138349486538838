.ck-editor {
  margin-top: 0 !important;
  width: 100% !important;
}
.cke_contents {
  min-height: 300px;
}

.ck-editor__editable_inline {
  min-height: 350px;
}

.min-height-editor {
  .cke_contents {
    min-height: 150px;
  }
}