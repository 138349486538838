.container {
  
  &.full-width {  
    max-width: none!important;
  }

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 2048px;
  }
}

.container__wrap {
  @include directify($directions) {
    #{directed('padding-left')}: 0px;
  }
  padding-top: 90px;
  min-height: 100vh;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    @include directify($directions) {
      #{directed('padding-left')}: 250px;
    }
  }

  &.no_left_menu {
    @media screen and (min-width: 576px) {
      @include directify($directions) {
        #{directed('padding-left')}: 0px;
      }
    }
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      @include directify($directions) {
        #{directed('padding-left')}: 0px;
      }

      @media screen and (min-width: 576px) {
        @include directify($directions) {
          #{directed('padding-left')}: 60px;
        }
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        @include directify($directions) {
          #{directed('padding-left')}: 0px;
        }
      }
    }
  }
}

h4.border-bottom {
  border-bottom: 1px solid #646777!important;
  padding-bottom: 8px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.label-error {
  color: $color-red;
}