.stripe-information {
  margin-bottom: 35px;
  border: 2px solid #e6ebf1;
  border-radius: 5px;
  padding: 20px 135px 20px 25px;
  background: url('../../../../public/images/payments/stripeLogo.svg') right 25px center no-repeat;
  background-size: 80px auto; 
}
.react-datepicker__tab-loop {
  display: inline-block;
}
.dashboard__carousel-slide {
  height: 106px;
}
.summary-div .date-picker--interval {
  margin-top: 20px;
  text-align: center;
}
//.date-picker__svg {
//  display: none;
//}
.dpickers-div {
  text-align: center;
}
.dpickers-div input {
  margin-top: 5px;
}
.summary {
  margin: 10px 0;
}
.summary-offer {
  margin: 35px 0 0 0;
}
.dashboard .dashboard__carousel .dashboard__carousel-slide .dashboard__carousel-title {
  color: #015097;
}
.creator-date {
  line-height: 36px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .creator-date {
    text-align: center;
  }
}