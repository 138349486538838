// APP COLORS
.il-color-deep-blue {
  color: $color-deep-blue !important;
}

.il-text-gray {
  color: $color-text-gray !important;
}

.il-bg-green {
  background-color: #28a745 !important;
}

.text-blue {
  color: $color-blue;
}

.color-danger {
  color: red;
}
.color-text {
  @include themify($themes) {
    color: themed('colorText');
  }
}
