.tt_leads_wrapper {
  margin-top: 12px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 2% 58% 2% 35%;
}

.tt_leads_box {
  font-size: 100%;
}

.tt_leads_airplaneIcon {
  grid-column: 1 / 2;
  grid-row: 1;
}
.tt_leads_dateIcon {
  grid-column: 3  ;
  grid-row: 1 ;
}
.tt_leads_date {
  white-space: pre-wrap;
  padding-top: 3px;
  grid-column: 4 ;
  grid-row: 1 / 3 ;
}
.tt_leads_locationIcon {
  grid-column: 1;
  grid-row: 2;
}
.tt_leads_location {
  padding-top: 3px;
  grid-column: 2;
  grid-row: 2;
}
.tt_leads_travelPreferences {
  padding-top: 3px;
  grid-column: 2 / 4;
  grid-row: 1;
}
.tt_leads_replyIcon {
  grid-column: 1;
  grid-row: 3;
}
.tt_leads_repliedBy {
  padding-top: 3px;
  grid-column: 2 / 5;
  grid-row: 3;
}