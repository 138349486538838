.creator-dashboard, .agent-dashboard {
  font-family: 'Roboto';
  .text-warning {
    color: $color-yellow !important;
  }
  .text-danger {
    color: $admin-color-danger !important;
  }
  .text-success {
    color: $admin-color-success !important;
  }
  table {
    th.MuiTableCell-head {
      strong {
        font-weight: normal;
        font-size: 0.9rem;
      }
    }
    .MuiTableCell-body {
      font-weight: bold;
      @include themify($themes) {
        color: themed('colorEmoji');
      }
      font-size: 0.9rem !important;
      .material-table__toolbar-button {
        color: $color-text-gray;
        svg{
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
  .summary-title {
    font-size: 1.3rem;
  }

  .dashboard-top-card{
    h5 {
      @include themify($themes) {
        color: themed('colorText');
      }
    }
    .dashboard__carousel-title{
      color: $color-deep-blue;
      font-weight: bold;
      font-size: 2rem;
    }
    .payout, .clicks, .conversions{
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 5px;
    }
    .payout{
      background: url('../../../../public/images/creator/payout.png') no-repeat $creator-color-payout; background-size: 90px; background-position: -5px 101%;
    }
    .clicks{
      background: url('../../../../public/images/creator/clicks.png') no-repeat $creator-color-clicks;  background-size: 80px; background-position: -5px 101%;
    }
    .conversions{
      background: url('../../../../public/images/creator/conversions.png') no-repeat $creator-color-conversions; background-size: 80px; background-position: -5px 101%;
    }
    @media screen and (max-width: 1024px) {
      .conversions, .clicks, .payout{
        background-image: none;
      }
    }
  }

  .card-info-label{
    font-size: 0.7rem;
    svg {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
  /* .alert{
    border-radius: 5px;
    border: 1px solid $color-blue;
    .notification__text{
      font-size: 14px;
      line-height: 18px;
      @include themify($themes) {
        color: themed('colorEmoji');
      }

    }
    &.alert--notification{
      background: #fff;
    }
    &.alert-info{
      .alert-title{
        color: $color-blue;
        font-weight: bold;
      }
      .text-icon{
        max-height: 20px;
        width: auto;
      }
      .close{
        color: $color-blue;
      }
    }


  } */
  .dashboard-title{
    font-size: 2.1rem;
    // text-transform: uppercase;
    position: relative;
    padding: 20px 0px 20px 60px;
    @include themify($themes) {
      color: themed('colorText');
    }
    margin-bottom: 20px;
    img{
      max-width: 50px;
      position: absolute;
      left: 0px;
      top: calc(50% - 24px);
    }
  }
  .card {
    min-width: 250px;
    h2{
      font-size: 1.6rem;
      font-weight: bold;
    }
    .card-footer{
      border: none;
      @include themify($themes) {
        background: themed('colorBackground');
      }
    }
    &.onboarding{
      .card-body {
        padding-top: 50px;
      }
      .card-img-top {
        padding: 20px 0px;
      }
    }
    .card-top-label{
      background: $color-deep-blue;
      color: $color-white;
      position: absolute;
      top: 0px;
      left: 0px;
      display: block;
      padding: 0px 10px;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
  .header-hero, .header-hero-agent{
    background: url('../../../../public/images/creator/hero_image.png') center left;
    background-size: cover;
    min-height: 140px;
    width: 100%;
    margin-bottom: 20px;
    color: $color-white;
    border-radius: 4px;
    h1{
      color: $color-white;
      font-size: 2.5rem;
      font-weight: bold;
      padding: 40px 0px 0px 0px;
    }
    .hero-text{
      font-size: 0.95rem;
    }
  }
}
.header-hero-agent {
  background: url('../../../../public/images/agent/hero_image.jpg') center center!important;
  h1{
    padding: 20px 0 0 0!important;
  }
}
.agent-dashboard {
  .dashboard-title {
    padding: 5px 0 20px 50px;
  }
  .dashboard-top-card{
    .payout{
      background: url('../../../../public/images/agent/payout.png') no-repeat $agent-color-payout; background-size: 127px; background-position: -5px 101%;
    }
    .clicks{
      background: url('../../../../public/images/agent/clicks.png') no-repeat $agent-color-clicks; background-size: 127px; background-position: -5px 101%;
    }
    .conversions{
      background: url('../../../../public/images/agent/conversions.png') no-repeat $agent-color-conversions; background-size: 127px; background-position: -5px 101%;
    }
  }
  .creator-info-title {
    background: url('../../../../public/images/agent/creatorOffers.png') no-repeat;
    background-size: 40px;
    min-height: 40px;
    width: 40px;
    margin-right: 8px;
  }
  .creator-name-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    .creator-name-label {
      border-radius: 5px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      h4 {
        font-weight: bold;
        color: #232329;
      }
      .creator-select-label {
        margin-top: 2px;
        font-weight: bold;
        color: #646777;
      }
    }
  }
}
