.creator-top-buttons {
  width: 120px;
  padding-left: 10px!important;
  padding-right: 10px!important;
  border-color: #f2f4f7 !important;
  background-color: #f2f4f7 !important;
  color: black !important;
  margin-bottom: 15px;

  &::before {
    background-color: darken(#f2f4f7, 10%) !important;
  }

  &.active {
    background-color: #0262cc !important;
    color: white!important;

    &::before {
      background-color: darken(#0262cc, 10%) !important;
    }
  }
  @media (min-width: 576px) {  
    
  }
}
@media (max-width: 575.98px) {
  .creator-top-buttons {
    width: 100%;
  }
}

.last-item-color {
  color: #1d49a1;
}

.offer-details-dashboard, .offer-details-dashboard-agent, .payables-dashboard-agent {

  table {
    th.MuiTableCell-head {
      strong {
        font-weight: normal;
        font-size: 0.9rem;
      }
    }
    .MuiTableCell-body {
      font-weight: bold;
      @include themify($themes) {
        color: themed('colorEmoji');
      }
      font-size: 0.9rem !important;
      .material-table__toolbar-button {
        color: $color-text-gray;
        svg{
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }

  .dashboard-top-card{
    h4 {
      @include themify($themes) {
        color: themed('colorText');
      }
    }
    .dashboard__carousel-title{
      color: $color-deep-blue;
      font-weight: bold;
      font-size: 2rem;
    }
    .payout, .clicks, .conversions{
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 5px;
    }
    .payout{
      background: url('../../../../public/images/creator/payout.png') no-repeat #ffeee6; background-size: 90px; background-position: -5px 101%;
    }
    .clicks{
      background: url('../../../../public/images/creator/clicks.png') no-repeat $creator-color-clicks;  background-size: 80px; background-position: -5px 101%;
    }
    .conversions{
      background: url('../../../../public/images/creator/conversions.png') no-repeat #d5dcff; background-size: 80px; background-position: 5px 101%;
    }
    @media screen and (max-width: 1024px) {
      .conversions, .clicks, .payout{
        background-image: none;
      }
    }
  }

  .panel__title {
    margin-bottom: 0;
  }
}

.offer-summary-panel, .offer-summary-panel-agent {
  .panel__body {
    padding-bottom: 25px;
  }
  .panel__title {
    margin-bottom: 0;
  }
}

h3.offer-name-icon {
  position: relative;
  margin-bottom: 15px;
}


.offer-name-logo {
  width: 35px;
  float: left;
  margin-right: 8px;
  margin-bottom: 10px;
}

.offer-details-dashboard-agent {
  .dashboard-title {
    padding: 5px 0 20px 50px;
  }
  .dashboard-top-card{
    .payout{
      background: url('../../../../public/images/agent/payout.png') no-repeat $agent-color-payout; background-size: 135px; background-position: -5px 101%;
    }
    .clicks{
      background: url('../../../../public/images/agent/clicks.png') no-repeat $agent-color-clicks; background-size: 135px; background-position: -5px 101%;
    }
    .conversions{
      background: url('../../../../public/images/agent/conversions.png') no-repeat $agent-color-conversions; background-size: 135px; background-position: -5px 101%;
    }
  }
}
