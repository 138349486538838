@import './settings/variable';
@import './vendor';
.radius-button {
  border-radius: 10px;
}
.main-header{font-size: 1.9rem; font-weight: bold; line-height: 25px;}
.company-header{font-size: 1.5rem; }
.admin-dashboard {
  font-family: 'Roboto', sans-serif;
  .badge {
    &.top-rounded {
      border-radius: 1rem !important;
      padding: 5px 15px;
      font-size: 0.9rem;
      vertical-align: top;
    }
    &.table-rounded {
      border-radius: 1rem !important;
      padding: 5px 15px;
      width: 100%;
      @include themify($themes) {
        color: themed('colorEmoji');
      }
    }
    @include themify($themes) {
      color: themed('colorEmoji');
    }
    font-weight: normal;
    &.badge-success{
      background: $admin-color-success;
    }
    &.badge-danger{
      background: $admin-color-danger;
    }
    &.badge-warning{
      background: $admin-color-warning;
    }
  }
  /* .alert {
    @include themify($themes) {
      color: themed('colorEmoji');
    }
    border: none;
    p{
      @include themify($themes) {
        color: themed('colorEmoji');
      }
    }
    &.alert-warning {
      background: $color-yellow;
    }
    &.alert-danger {
      background: $admin-color-danger;
    }
    &.alert-success {
      background: $admin-color-success;
    }
  } */
}
main {
  padding: 0;
}
.icon-button {
  background: transparent;
  border: none;
  padding: 0px;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    transform: scale(1.1);
    @include themify($themes) {
      color: themed('colorEmoji');
    }
  }
}
.section-title{
  font-size: 1.9rem;
}
.admin-table-container {
  .material-table__toolbar-wrap{
    display: none;
  }
  .material-table__wrap{
    min-height: auto;
  }
  .material-table__main-toolbar{
    display: none;
  }
  table {
    th.MuiTableCell-head {
      strong {
        font-weight: normal;
        font-size: 0.9rem;
      }
    }
    .MuiTableCell-body {
      font-weight: bold;
      @include themify($themes) {
        color: themed('colorEmoji');
      }
      font-size: 0.9rem !important;
      .material-table__toolbar-button {
        color: $color-text-gray;
        svg{
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
}

.creator-profile {
  .back-button-col {
    button {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      margin-top: 0px !important;
    }
  }
  .css-yk16xz-control, .css-1pahdxg-control {
    padding: 0.25rem 0 0.25rem 0;
  }
  .small-height {
    .css-yk16xz-control, .css-1pahdxg-control {
      padding: 0;
    }
  }
}

.tokens-list::-webkit-scrollbar {
  width: 10px;
}

.tokens-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.tokens-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

.tokens-list-negative-margin {
  margin-right: -10px;
}

.time-period-filter-buttons {
  width: 120px;
  padding-left: 10px!important;
  padding-right: 10px!important;
  border-color: #70bbfd !important;
  background-color: #f2f4f7 !important;
  color: black !important;
  margin-bottom: 15px;

  &::before {
    background-color: darken(#f2f4f7, 10%) !important;
  }

  &.active {
    background-color: #70bbfd !important;
    border-color: #70bbfd !important;
    color: white!important;

    &::before {
      background-color: darken(#70bbfd, 10%) !important;
    }
  }
}
.time-period-filter-buttons-extended {
  width: 100px;
  padding-left: 0px!important;
  padding-right: 0px!important;
  border-color: #70bbfd !important;
  background-color: #f2f4f7 !important;
  color: black !important;
  margin-bottom: 15px;
  font-size: 12px;

  &::before {
    background-color: darken(#f2f4f7, 10%) !important;
  }

  &.active {
    background-color: #70bbfd !important;
    border-color: #70bbfd !important;
    color: white!important;

    &::before {
      background-color: darken(#70bbfd, 10%) !important;
    }
  }
}

.creator-top-buttons {
  width: 120px;
  padding-left: 10px!important;
  padding-right: 10px!important;
  border-color: #f2f4f7 !important;
  background-color: #f2f4f7 !important;
  color: black !important;
  margin-bottom: 15px;

  &::before {
    background-color: darken(#f2f4f7, 10%) !important;
  }

  &.active {
    background-color: #70bbfd !important;
    color: white!important;

    &::before {
      background-color: darken(#70bbfd, 10%) !important;
    }
  }
}

.media-content-dashboard-container {
  table {
    th:last-child, td:last-child {
      min-width: 50px!important;
      max-width: 50px!important;
    }
    thead tr {
      :nth-child(7) span {
        justify-content: center;
      }
      :nth-child(10) span {
        justify-content: center;
      }
      //:nth-child(6) {
      //  min-width: 50px!important;
      //  max-width: 50px!important;
      //}
    }
    tbody tr {
      :nth-child(7) {
        text-align: center;
      }
      :nth-child(10) {
        text-align: center;
      }
      //:nth-child(6) {
      //  min-width: 50px!important;
      //  max-width: 50px!important;
      //}
    }
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #70bbfd;
  }
}

.ai-comments-dashboard {
  table {
    thead tr {
      align-items: center;
      border-bottom: 2px solid #eff1f5;
    }
    thead th {
      border-bottom-color: white!important;
    }
  }
}

.media-content-scorecard-container {
  table {
    thead tr {
      :nth-child(4) span {
        justify-content: center;
      }
      :nth-child(3) span {
        justify-content: center;
      }
    }
  }
}

.agent-users-dashboard-container {
  table {
    thead tr :nth-child(7) {
      flex: 100 0 auto!important;
      width: 75px!important;
    }
    tbody tr :nth-child(7) {
      flex: 100 0 auto!important;
      width: 75px!important;
    }
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #70bbfd;
  }
}

.scorecard-definition-dashboard-container {
  table {
    thead tr :nth-child(5) {
      flex: 80 0 auto!important;
      width: 55px!important;
      text-align: center;
    }
    tbody tr :nth-child(5) {
      flex: 80 0 auto!important;
      width: 55px!important;
      text-align: center;
    }
  }
}

.agent-creators-list-dashboard {
  table {
    min-height: 200px;
    tfoot {
      display: none;
    }
    tr:last-child {
      border-bottom: 1px solid #eff1f5 !important;
    }
  }
}

.research-dashboard-container {
  table {
    .material-table__toolbar-button {
      color: #70bbfd;
      svg{
        width: 16px !important;
        height: 16px !important;
      }
    }
  }

  thead th {
    min-width: 80px !important;
  }
}

.research-creator-account-info-grid-cell {
  min-width: 100px !important;
  width: 220px !important;
}

.research-video-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.block-in-cell {
  .material-table__toolbar-button {
    color: #70bbfd;
  }
}

.total-area-block {
  padding: 15px 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color:rgba(0, 0, 0, 1);

  .total-area-item-value{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
    width: 100%;
    text-align: center;
  }
  .total-area-item-label {
    font-size: 16px;
    width: 100%;
  }
  br{
    line-height: 50%
  }
}

.edit-prospect-tags {
  .react-tags {
    max-height: 38px;
    padding: 3px 0 0 6px;
    line-height: 0.8;
  }
}
.checkbox-in-prospects {
  .il-checkbox-btn__checkbox-custom {
    margin-top: 5px;
    svg {
      margin-top: -15px;
    }
  }
}

.datepicker-in-prospects {
  height: 44px !important;
  max-height: 44px !important;
  font-size: 1em;
}

.datepicker-in-profile {
  height: 38px !important;
  max-height: 38px !important;
  font-size: 1em;
}

.date-picker-full-width {
  .react-datepicker__input-container {
    width: inherit;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.standard-close-cross {
  .close {
    right: 0 !important;
    top: 0 !important;
    color: inherit;
    font-size: 24px;
    line-height: 34px;
  }
}

.list-items-color-blue {
  list-style-image: url('../shared/img/circle_blue.svg');
}

.text-as-link {
  &:hover {
    text-decoration: none;
  }
}
