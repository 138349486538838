.finance-container .panel__body {
  padding: 10px 0;
}
.finance-container .panel__btns {
  top: 25px;
}
.finance-container .card {
  padding-bottom: 10px;
  padding-top: 10px;
}
.finance-container .card-body {
  padding: 10px;
  height: auto;
}
.d-label {
  text-align: right;
  @include themify($themes) {
    color: themed('colorText');
  }
}
.d-label label {
  line-height: 35px;
  vertical-align:middle;
}
.finance-form b {
  float: left;
}
.finance-form .react-datepicker-wrapper, .deal-editor .react-datepicker-wrapper {
  width: 100%;
}
.year-month {
  padding: 31px 0!important;
}
.delete-modal .modal-content {
  padding: 0;
}
.delete-modal .btn {
  margin-bottom: 0;
}