.modal {

}

.modal-dialog { // ????????
  min-width: 700px;
}
.modal-dialog.modal-dialog-lg { // ????????
  min-width: 800px;
}
.modal-content {
  border-radius: 0;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 20px 20px 30px 20px;
  text-align: left;

}

.modal__close-btn {
  position: absolute;
  top: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;

}

.modal__title-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.modal__header {
  margin-top: 10px;
  margin-bottom: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.modal__body {
  font-size: 125%;
}

.modal__body table {
  font-size: 80%;
}

.modal__footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
  }

}
