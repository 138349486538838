.documents-section {
  .documents-title {
    text-transform: uppercase;
  }

  .documents-title-image {
    float: left;
    margin-right: 10px;
    margin-top: 2px;
    width: auto;
    height: 27px;
  }

  .creator-documents-table {
    table {
      th.MuiTableCell-head {
        strong {
          font-weight: normal;
          font-size: 0.9rem;
        }
      }
      .MuiTableCell-body {
        font-weight: bold;
        @include themify($themes) {
          color: themed('colorEmoji');
        }
        font-size: 0.9rem !important;
        .material-table__toolbar-button {
          color: $color-text-gray;
          svg{
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
    }

    .creator-doc-table-btn {
      border-radius: 12px;

      span.lnr {
        margin-right: 4px;
      }
    }

    .marker-deep-blue {
      color: white;
    }
  }
}
