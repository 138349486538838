.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  background: #f2f4f7;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure it sits above other content */
}
