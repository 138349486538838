#adminUserPermissionsTable td {
    padding: 5px !important;
    font-size: 14px !important;
}

#adminUserPermissionsTable table th:nth-child(-n + 2) {
    width: 10%;
}

#adminUserPermissionModal .modal-header {
    padding-top: 0px !important;
}

#selectAllAdminUsersCheckBox {
    display: flex;
    justify-content: flex-start;
    padding: 5px;
}
#selectAllAdminUsersCheckBox p, h1 {
    margin: 0px;
}
