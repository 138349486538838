.il-payments-logo {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 0;
}

.il-payments-green-link {
  color: #219f72;
  font-size: 15px;
  font-weight: 500;
}

.il-payments-green-link:hover, .il-payments-green-link:focus {
  color: #219f72;
  text-decoration: underline;
}

.il-payments-black-link {
  color: black;
  font-size: 15px;
  font-weight: 500;
  text-decoration: underline;
  margin-left: 5px;
}

.il-payment-pay-btn {
  border-radius: 10px;
  box-shadow: 0 2px 5px -1px rgba(50, 50, 93, 0.25), 0 1px 3px -1px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto';
}
.il-payments-pay-btn:hover, .il-payments-pay-btn:focus {
  background-color: #218838!important;
  background-bottom-color: #218838!important;
  border-color: #1e7e34!important;
}
.btn.btn-success:before {
  background-color: #15b67c!important;
}

.il-payments-balance-label {
  font-size: 14px;
  font-weight: 500;
}

.il-payments-balance {
  color: #015097!important;
  font-weight: bold;
  font-size: 2rem;
}

.il-payments-available-balance {
  font-size: 14px;
  font-weight: 500;
  color: #015097!important;
}

.il-payments-balance-title {
  text-align: right;
  min-width: 100px;
}

.payment-title {
  .payment-title-image {
    float: left;
    margin-right: 5px;
    margin-top: 2px;
    width: auto;
    height: 27px;
  }

  .creator-settings-icon {
    border-color: transparent;

    &:hover, &:focus {
      border-color: transparent;
    }
    &::before {
      background-color: transparent;
    }
  }
}

.panel-no-vertical-padding {
  .panel__body {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .panel__title {
    margin-bottom: 0;
  }
}

.creatorPaymentsPage{
  h5 {
    @include themify($themes) {
      color: themed('colorText');
    }
  }
  .dashboard__carousel-title{
    color: $color-deep-blue;
    font-weight: bold;
    font-size: 2rem;
  }
  .payout, .payments{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
  }
  .payout{
    background: url('../../../../public/images/creator/payout.png') no-repeat $creator-color-payout; background-size: 90px; background-position: -5px 101%;
  }
  .payments{
    background: url('../../../../public/images/creator/coinIcon.png') no-repeat $creator-color-clicks;  background-size: 80px; background-position: -5px 101%;
  }

  @media screen and (max-width: 1024px) {
    .payments, .payout{
      background-image: none;
    }
  }
}

.il-payment-card-balance {
  padding-bottom: 0;
  min-width: 250px;

  .il-payments-balance-body {
    background: url(../../../../public/images/creator/paymentSymbolGrey.png) no-repeat #eff4f7;
    background-size: 53px;
    background-position: -13px -22px;
  }
}

.transfers-table {
  table {
    th.MuiTableCell-head {
      strong {
        font-weight: normal;
        font-size: 0.9rem;
      }
    }
    .MuiTableCell-body {
      font-weight: bold;
      @include themify($themes) {
        color: themed('colorEmoji');
      }
      font-size: 0.9rem !important;
      .material-table__toolbar-button {
        color: $color-text-gray;
        svg{
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
}

.il-payments-badge {
  text-align: center;
  color: black;
  font-weight: 400;
  padding: 3px 20px;
  display: inline-block;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-transform: capitalize;

  &.il-payments-badge-green {
    background-color: #4ce1b6 !important;
    color: black;
  }

  &.il-payments-badge-red {
    background-color: $color-red !important;
    color: white;
  }
}
