#paymentBlock td, #mediaConentBlock td, #conversionsBlock td {
    vertical-align: middle !important;
    font-weight: bolder !important;
    color: black;
    padding: 10px;
}

#mediaConentBlock td {
    height: 110px;
}

#paymentBlock th, #mediaConentBlock th, #conversionsBlock th {
    font-weight: normal !important;
}

#mediaConentBlock button {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    border-radius: 9px;
}

#conversionsBlockButton{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    border-radius: 9px;
    margin-top: 3px;
}

#paymentBlock .pagination, #mediaConentBlock .pagination, #conversionsBlock .pagination {
    float: right;
    padding-right: 5px;
}